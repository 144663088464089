export const stockData = [
  {
    id: 1,
    name: "Accessories",
    des:"Accessories are used to connect different parts of a product according to the requirements of the customer & it's also used for convenience, attractiveness, safety, etc. It's very easy to disassemble these accessories if any changes need to be done.",
    imagePath: "assets/aceesories/(a2) ALUMINIUM PROFILE ACCESSORIES.jpg",
    subcategories: [
      {
      id: 11,
      name: "Aluminium Profiles & Accessories",
      productImage: [
      {
      id: "a1",
      name: "ALUMINIUM PROFILES 30x30 40x40 40X80",
      des:"Aluminium extrusion profile 30x30 40x40 40x80 are used to assemble racks, conveyors, tables, partitions, etc. Aluminium extrusion profile is most commonly used across industrial automation applications such as automated motion applications on process lines and industries throughout manufacturing. They are strong and yet light enough that they can be easily man-handled and built into various configurations as necessitated based on requirements. Aluminium Profile is one of the most cost effective profiles alongside steel and iron used in industries today. It has many unique qualities that make it favorable for various applications compared to the other common metals. Aluminium Extrusion Profile is usually considered a lightweight material but it is in fact extremely strong and versatile. Aluminium Profile has a longer life than items made from steel or plastic. Besides, extrusion is meant to be the most environmentally friendly and economical solution.",
      imagePath: "assets/aceesories/(a1) ALUMINIUM PROFILES 30x30 40x40 40X80.jpg",
      },
      {
      id: "a2",
      name: "ALUMINIUM PROFILE ACCESSORIES",
      des:"Aluminium profile accessories are used to connect 2 or more aluminium extrusion profiles with nut bolts to build up a strong & light weight frame of racks, trolleys, working tables, conveyors, notice board, stands, partitions, etc.",
      imagePath:
      "assets/aceesories/(a2) ALUMINIUM PROFILE ACCESSORIES.jpg",
      },
      {
      id: "a3",
      name: "ALUMINIUM GUIDE RAIL CLAMPS",
      des:"Aluminium guide rail clamps are mainly used to give direction to a part moving on the belt conveyor. Aluminium guide rail clamp is made of reinforced polyamide & has a stainless steel rod in some clamps with nut bolts to assemble it with aluminium extrusion profiles. Aluminium guide rail clamps are easily adjustable as per our requirement on running conveyors.",
      imagePath: "assets/aceesories/(a3) ALUMINIUM GUIDE RAIL PARTS.jpg",
      },
      ],
      },
      {
      id: 12,
      name: "Pipe & Joint System & Accessories",
      productImage: [
      {
      id: "a5",
      name: "PIPE DIA 28 MM",
      des:"Pipe dia 28mm are mostly useful in the pipe joint system. This pipe joint system can be assembled by selecting between the various types of pipes like goblin pipe, stainless steel pipes, powder coated on mild steel pipes, aluminium pipe, slide pipe & ESD pipe. These pipes can be assembled with joints & nut bolts. This pipe joint system is easy to disassemble & shift to another place. The pipe joint system is capable of bearing 200kg/sq.mtr. so it is widely useful for automobile industrial, offices, logistics, hospitals, hotels & many more places.",
      imagePath: "assets/aceesories/(a5) PIPE DIA 28 MM.jpg",
      },
      {
      id: "a6",
      name: "METAL JOINTS FOR RACK",
      des:"Metal joints of the pipe & joint system are used to connect 2 or more pipes of dia 28mm with nut bolts to build up a strong & light weight frame of racks, trolleys, working tables, conveyors, notice board, stands, partitions, etc. Most common metal joints are made of mild steel with black colour powder coated. Metal joints are heavily useful for automobile industries, logistics, offices & hotels. Metal joints weight capacity is 200kg/ Sqmtr.",
      imagePath: "assets/aceesories/(a6) METAL JOINTS FOR RACK.png",
      },
      {
      id: "a7",
      name: "PLACON CLAMPS FOR CONVEYOR",
      des:"lacon clamps are widely useful to assemble FIFO conveyors by connecting placon to placon or pipe to placon. Placon clamps are made of stainless steel to increase the strength of gravity conveyor joints. Placon clamps are selected according to the type of placon used in the gravity conveyor.  Most placon clamps don't need nut bolts to assemble or disassemble so it is effortless & time efficient.",
      imagePath: "assets/aceesories/(a7) PLACON CLAMPS FOR CONVEYOR.jpg",
      },
      ],
      },
      {
      id: 13,
      name: "Casters",
      productImage: [
      {
      id: "a9",
      name: "CASTERS",
      des:"Casters are used to move trolleys, conveyors, working tables, stands, etc. from one place to another. Casters are selected according to product weight, ground clearance, area temperature or surface material. Casters have their own types like swivel, fixed, break with swivel & many more. Casters are made of material like PU, Nylon, PP, M.S, Rubber, Etc.",
      imagePath: "assets/aceesories/(a9) CASTERS.jpg",
      },
      ],
      },
  ],
  },
  {
  id: 2,
  name: "Electric Conveyors",
  des:"The electric conveyors are working by electricity & it's used in many industries as a standard piece of mechanical handling equipment to move goods, products, raw material and other goods  from one location to another, usually in the same area or building with the help of gear, motor, VFD, etc.",
imagePath: "assets/ElectricConveyors/(b4) Aluminium Conveyor.jpg",
  subcategories: [
  {
  id: 21,
  name: "Aluminium Belt Conveyor",
  productImage: [
  {
  id: "b4",
  name: "Aluminium Conveyor",
  des:"Aluminium conveyors are made with  aluminium profiles 40x40 & 40x80 joints with nut bolts on the aluminium bracket. One of the basic tools in the material handling industry, belt conveyors are most commonly used in transportation of materials. guide rail clamps used with plastic strips to guide material moving on the belt conveyor. Aluminium belt conveyors are light weight & weldless as compared to mild steel & stainless steel conveyor which makes it user friendly.",
  size:"3500L X 350W X 900H",
  Material:" Aluminium profile, PVC conveyor belt & plastic strip guide",
  imagePath: "assets/ElectricConveyors/(b4) Aluminium Conveyor.jpg",
  },
  {
  id: "b1",
  name: "Aluminium Belt Conveyor",
  des:"Aluminium belt conveyor systems consist of two or more pulleys (a.k.a. drums). An endless loop of carrying medium—the conveyor belt— rotates about them. To move the belt and the material it carries forward, one or both pulleys are powered. The powered pulley is called “drive pulley,” the unpowered one is known as “idler pulley.” Aluminium belt conveyors are made by aluminium profiles 40x40 & 40x80 joints with nut bolts on the aluminium bracket. Aluminium belt conveyors are used to shift material from one place to another.",
  size:"3500L X 350W X 900H",
  Material :"Aluminium profile, PVC conveyor belt & plastic strip guide",
  imagePath:
  "assets/ElectricConveyors/(b1) Aluminium Belt Conveyor.jpg",
  },
  {
  id: "b2",
  name: "Aluminium Belt Conveyor",
  des:"Aluminium belt conveyors are made with aluminium profiles 40x40 & 40x80 joints with nut bolts on the aluminium bracket.Aluminium belt conveyors are light weight & weldless as compared to mild steel & stainless steel conveyor which makes it user friendly. Different types of accessories make it easy to assemble & disassemble.",
  size:"5000L X 300W X 920H",
  Material :"Aluminium profile & PVC conveyor belt",
  imagePath:
  "assets/ElectricConveyors/(b2) Aluminium Belt Conveyor.jpg",
  },
  ],
  },
  {
  id: 22,
  name: "Stainless Steel (SS) Belt Conveyor",
  productImage: [
  {
  id: "b5",
  name: "SS Belt Conveyor",
  des:"S.S. belt conveyors are made of stainless steel material consisting of two or more pulleys (a.k.a. drums). An endless loop of carrying medium—the conveyor belt— rotates about them. To move the belt and the material it carries forward, one or both pulleys are powered. The powered pulley is called “drive pulley,” the unpowered one is known as “idler pulley.",
  size:"1500L X 250W X 900H",
  Material :"Stainless steel sheet, stainless steel pipe",
  imagePath: "assets/ElectricConveyors/(b5) SS Belt Conveyor.jpg",
  },
  {
  id: "b6",
  name: "SS Belt Conveyor",
  des:"S.S belt conveyors are made of stainless steel material assembled with nut bolts & various parts. Ss belt conveyors are heavier than aluminium belt conveyors. Fabrication cost of (S.S) stainless steel belt conveyor is higher than aluminium belt conveyor.",
  size:"4500L X 1000W X 900H",
  Material :"Stainless steel & PVC conveyor belt",
  imagePath: "assets/ElectricConveyors/(b6) SS Belt Conveyor.jpg",
  },
  ],
  },
  {
  id: 23,
  name: "Mild Steel (MS) Belt Conveyor",
  productImage: [
  {
  id: "b7",
  name: "MS Belt Conveyor With Guide",
  des:"M.S. belt conveyors are made of mild steel material are most commonly used in transportation of materials. guide rail clamps used with plastic strips to guide material moving on the belt conveyors. Mild steel (M.S.) belt conveyors are heavier than stainless steel belt conveyors & aluminium belt conveyors.",
  size:"1200L X 500W X 850H",
  Material :"Mild Steel",
  imagePath:
  "assets/ElectricConveyors/(b7) MS Belt Conveyor With Guide.jpg",
  },
  {
  id: "b8",
  name: "MS Belt Nut Bolt Conveyor",
  des:"M.S. (mild steel) belt conveyors are made of mild steel material assembled with nut bolts & various types of accessories. M.S. (mild steel) belt conveyors are most commonly used in transportation of materials. Belt conveyor consisting of two or more pulleys (a.k.a. drums). An endless loop of carrying medium—the conveyor belt— rotates about them. To move the belt and the material it carries forward, one or both pulleys are powered. The powered pulley is called “drive pulley.",
  size:"1800L X 400W X 800H",
  Material :"Mild Steel",
  imagePath:
  "assets/ElectricConveyors/(b8) MS Belt Nut Bolt Conveyor.jpg",
  },
  ],
  },
  ],
  },
  {
  id: 3,
  name: "Gravity Conveyors",
  des:"The Gravity conveyors are working by gravitational force & it's used in many industries as a mechanical handling equipment to move goods, products, raw goods, and other materials from one location to another without any electric equipment. Gravity Conveyors are cheaper as compared to electric conveyors.",
  imagePath: "assets/GravityConveyors/(c12) Plastic Bins FIFO Conveyor.jpg",
  subcategories: [
  {
  id: 31,
  name: "Aluminium Pipe Joint Placon Conveyor",
  productImage: [
  {
  id: "c1",
  name: "Aluminium 40X40 Placon Conveyor",
  des:"Aluminium 40X40 Placon Conveyors are assembled by the Aluminium profile 40x40 with placons with various accessories which are suitable to complete the application. Aluminium placon conveyors are made of such light materials that the handling of the product becomes very easy. For the purposes of producing also, it is just not a mandate to equip special facilities. It is even highly protective and an accommodating fit at all the workplaces.",
  size:"1400L X 600W X 1600H",
  shelves:"4 nos.",
  Material:" Aluminium profile",
  imagePath:
  "assets/GravityConveyors/(c1) Aluminium 40X40 Placon Conveyor.jpg",
  },
  {
  id: "c2",
  name: "Aluminium Profile 40x40 Roller Conveyor",
  des:"Aluminium 40X40 Placon Conveyors are assembled by the Aluminium profile 40x40 with rollers made with mild steel, galvanized iron, pp, nylon, etc. with various accessories as per suitable to complete the application. Aluminium profile conveyors are made of such light materials that the handling of the product becomes very simple. For the purposes of producing also, it is just not a mandate to equip special facilities. It is even highly protective and an accommodating fit at all the workplaces.",
  imagePath:
  "assets/GravityConveyors/(c2) Aluminium Profile 40x40 Roller Conveyor.jpg",
  },
  {
  id: "c3",
  name: "Aluminium Pipe Joint FIFO Conveyorr",
  des:"Aluminium pipe joint FIFO (First In First Out) conveyors are made by aluminium pipe dia 28mm, placons & black metal joints with nut bolts. These pipe & joint systems come with mesmerizing features like gentle producing without the need of any kind of specialized skills. Moreover it has very easy & simple assembling In case one makes mistakes also, rectification is very simple.",
  size:"1800L X 800W X 1600H",
  shelves:"3 nos.",
  Material:"Aluminium pipe",
  imagePath:
  "assets/GravityConveyors/(c3) Aluminium Pipe Joint FIFO Conveyor.jpg",
  },
  ],
  },
  {
  id: 32,
  name: "Stainless Steel (SS) Pipe Joint Placon Conveyor",
  productImage: [
  {
  id: "c5",
  name: " Stainless Steel Pipe Joint Conveyor",
  des:"Stainless steel pipe joint conveyors are assembled by ss (stainless steel) pipe dia 28mm with black metal joints & placons rollers. Stainless steel pipe joint conveyors are made of such light materials that the handling of the product becomes very unproblematic.",
  size:"1800L X 800W X 2200H",
  shelves:"7 nos.",
  Material:"Aluminium pipe",
  imagePath:
  "assets/GravityConveyors/(c5) Stainless Steel Pipe Joint Conveyor.jpg",
  },
  {
  id: "c6",
  name: "SS FiFO Conveyor",
  des:"Stainless steel pipe joint conveyors are assembled by ss (stainless steel) pipe dia 28mm with black metal joints & placons rollers. SS FIFO CONVEYORS are made of such light materials that the handling of the product becomes very unproblematic.For the purposes of producing also, it is just not a mandate to equip special facilities. It is even highly protective and an accommodating fit at all the workplaces",
  Material:"Stainless steel pipe (Dia 28 o.d.)",
  shelves:"3 nos.",
  imagePath: "assets/GravityConveyors/(c6) SS FiFO Conveyor.jpg",
  },
  {
  id: "c7",
  name: "Stainless Steel Pipe FIFO Conveyor",
  des:"Stainless steel pipe joint conveyors are assembled by ss (stainless steel) pipe dia 28mm with black metal joints & placons rollers. Pipe and Joint Systems is a brilliant, changing and adaptable particular get together framework consisting of plastic covered steel pipes and metal joints. The framework is flexible to the point that it could be made into different sorts of racks, trolleys, workstations, gravity stream racks & even light obligation machine structures.",
  imagePath:
  "assets/GravityConveyors/(c7) Stainless Steel Pipe FIFO Conveyor.jpg",
  },
  ],
  },
  {
  id: 33,
  name: "Mild Steel (MS) Powder Coated Pipe & Joint Placon Conveyor",
  productImage: [
  {
  id: "c8",
  name: "MS Pipe Placon Conveyor",
  des:"MS (mild steel) pipe joint placon conveyors are assembled by m.s. (mild steel) pipe dia 28mm with placons rollers & metal joints. M.S. (mild steel) pipe placon FIFO CONVEYORS are made of such light materials that the handling of the product becomes very unproblematic. For the purposes of producing also, it is just not a mandate to equip special facilities. It is even highly protective and an accommodating fit at all the workplaces.",
  size:"3000L X 1500W X 2100H",
  Material:"M.S. Pipe",
  shelves:"4 nos.",
  imagePath:
  "assets/GravityConveyors/(c8) MS Pipe Placon Conveyor.jpg",
  },
  {
  id: "c9",
  name: "Powder Coated Pipe Joint Conveyor",
  des:"Powder coated pipe joint placon conveyors are assembled by powder coated m.s. (mild steel) pipe dia 28mm with placons rollers & metal joints. These pipe & joint systems come with mesmerizing features like gentle producing without the need of any kind of specialized skills. Moreover it has very easy & simple assembling In case one makes mistakes also, rectification is very simple.",
  size:"3500L X 1300W X 2000H",
  Material:"M.S. Pipe",
  shelves:"4 nos.",
  imagePath:
  "assets/GravityConveyors/(c9) Powder Coated Pipe Joint Conveyor.jpg",
  },
  ],
  },
  {
  id: 35,
  name: "ABS (Plastic) Coated Pipe Joint Placon Conveyor",
  productImage: [
  {
  id: "c10",
  name: "ABS Coated Pipe Nut Bolt Conveyor",
  des:"ABS Coated pipe joint nut bolt conveyors are assembled by  ABS coated on m.s. (mild steel) pipe dia 28mm (outer dimension) with placons rollers & metal joints. A coat of ABS resin makes it spic and span and long lasting. In case one makes mistake also, rectification is very simple.",
  shelves:"3 nos.",
  Material:"ABS coated pipe (Goblin pipe)",
  imagePath:
  "assets/GravityConveyors/(c10) ABS Coated Pipe Nut Bolt Conveyor.jpg",
  },
  {
  id: "c11",
  name: "Plastic Coated Pipe Joint Bins Conveyor",
  des:"Plastic (ABS) Coated pipe joint nut bolt conveyors are assembled by  ABS coated on m.s. (mild steel) pipe dia 28mm (outer dimension) with placons rollers & metal joints. Pipe and Joint Systems is a brilliant, changing and adaptable particular get together framework consisting of plastic covered steel pipes and metal joints.",
  shelves:"5 nos.",
  size :"2000L X 800W X 2200H",
  Material:"Goblin pipe",
  imagePath:
  "assets/GravityConveyors/(c11) Plastic Coated Pipe Joint Bins Conveyor.jpg",
  },
  ],
  },
  {
  id: 36,
  name: "ABS (Plastic) Coated Pipe Joint Placon Conveyor",
  productImage: [
  {
  id: "c12",
  name: "Plastic Bins FIFO Conveyor",
  des:"Pipe & Joint Systems are the backbone of modern industries today. The best of the pipe & joint systems provide for metal joints/placons/genuine spare parts/plastic joints/bolt/nut & tools. it is widely useful for the storage of plastic bins by first in first out method. Pipe and Joint Systems is a brilliant, changing and adaptable particular get together framework consisting of plastic covered steel pipes and metal joints",
  shelves:"5 nos.",
  Material:"Goblin pipe (plastic coated pipe)",
  imagePath:
  "assets/GravityConveyors/(c12) Plastic Bins FIFO Conveyor.jpg",
  },
  {
  id: "c13",
  name: "PLASTIC (ABS) COATED STEEL PIPE BINS CONVEYOR",
  des:"Plastic (ABS) Coated pipe joint nut bolt conveyors are assembled by  ABS coated on m.s. (mild steel) pipe dia 28mm (outer dimension) with placons rollers & metal joints. The best of the pipe & joint systems provide for metal joints/placons/genuine spare parts/plastic joints/bolt/nut & tools. it is widely useful for the storage of plastic bins by first in first out method. coat of ABS resin which makes it spic and span and long lasting. In case one makes a mistake also, rectification is very simple.",
  shelves :"3 nos.",
  size:"2000L X 800W X 1800H",
  Material:"Goblin pipe (ABS coated pipe)",
  imagePath:
  "assets/GravityConveyors/(c13) PLASTIC (ABS) COATED STEEL PIPE BINS CONVEYOR.jpg",
  },
  ],
  },
  ],
  },
  {
  id: 4,
  name: "Workstations",
  des:"Workstations are assembled by different pipes , profile , joints , accessories of different materials made by plastics, aluminium , stainless steel, mild steel to make a solid structure suitable for different purposes of work application. A perfect workstation can save time, money & energy.",
  imagePath: "assets/Workstations/(d1) Aluminium Profile Assembly Table.jpg",
  subcategories: [
  {
  id: 41,
  name: "Aluminium Profile 40x40 Workstation ",
  productImage: [
  {
  id: "d1",
  name: "Aluminium Profile Assembly Table",
  des:"Aluminium Profile Assembly Tables are assembled by aluminium profiles & accessories of aluminium profiles selected by weight capacity of tables. These Aluminum profile assembly tables are used for performing different tasks safely and easily. These aluminium profile tables come with mesmerizing features like gentle producing without the need of any kind of specialized skills. Moreover it has very easy & simple assembling In case one makes mistakes also, rectification is very simple.",
  size:"1600L X 500W X 2200H",
  Material:"Aluminium profile",
  imagePath:
  "assets/Workstations/(d1) Aluminium Profile Assembly Table.jpg",
  },
  {
  id: "d2",
  name: "Assembly Line Aluminium Workstation",
  des:"Assembly Line Aluminium Workstations are very useful for assembly lines which are made of such light materials that the handling of the product becomes very simple. Various types of sheet like HDPE, Nylon, Stainless Steel, Mild Steel, ESD, Rubber, Wooden Board, etc. can be selected for table top according to the work performed on it. For the purposes of producing also, it is just not a mandate to equip special facilities. When these aluminium tables will connect with aluminium belt conveyors then It can boost the production line & will definitely give beneficial results.",
  size:"800L X 600W X 2000H",
  Material:" Aluminium profile",
  imagePath:
  "assets/Workstations/(d2) Assembly Line Aluminium Workstation.jpg",
  },
  {
  id: "d3",
  name: "Aluminium Profile 40x40 Working Table",
  des:"Aluminium Profile 40x40 working Tables are assembled by aluminium profiles & accessories of aluminium profiles selected by weight capacity of tables. These Aluminum profile working tables are used for performing different tasks safely and easily. These aluminium profile working tables come with mesmerizing features like gentle producing without the need of any kind of specialized skills. Moreover it has very easy & simple assembling In case one makes mistakes also, rectification is very simple.",
  size:"1200L X 500W X 2000H",
  Material:"Aluminium profile",
  imagePath:
  "assets/Workstations/(d3) Aluminium Profile 40x40 Working Table.jpg",
  },
  ],
  },
  {
  id: 42,
  name: "Aluminium Pipe & Joint Workstation",
  productImage: [
  {
  id: "d5",
  name: "Aluminium Pipe Joint Workstation",
  des:"Aluminium Pipe Joint Workstation are widely used in automobile industries because of its various advantages like light weight, weldless, easy assembly, reforming activities are possible in a continuous fashion providing scope for modification. Dia 28 mm aluminium pipe is used in this table which is assembled with metal joints by nut bolt .",
  size:"4000L X 700W X 2000H",
  Material:"Aluminium pipe",
  imagePath:
  "assets/Workstations/(d5) Aluminium Pipe Joint Workstation.jpg",
  },
  ],
  },
  {
  id: 43,
  name: "Stainless Steel (SS) Pipe & Joint  Workstation",
  productImage: [
  {
  id: "d6",
  name: "SS Pipe Joint Assembly Table",
  des:"SS (stainless steel) Pipe Joint Assembly Tables are assembled by stainless steel pipe dia 28mm with nut bolt metal joints which are selected according to the framework. Various types of sheet like HDPE, Nylon, Stainless Steel, Mild Steel, ESD, Rubber, Wooden Board, etc. can be selected for table top according to the work performed on it.",
  size:"900L X 900W X 2000H",
  Material:"Stainless steel pipe",
  imagePath:
  "assets/Workstations/(d6) SS Pipe Joint Assembly Table.jpg",
  },
  {
  id: "d7",
  name: "Stainless Steel Pipe Nut Bolt Joint Table",
  des:"Stainless Steel pipe nut bolt joint tables are made up of stainless steel pipe with various accessories like joints, nut bolts, height adjusters, plastic caps, name plates, etc. These tables are multi-purpose and versatile and have a shiny and polished finish. They are easy to clean when compared to tables made of other materials such as wood and plastic.",
  size:"2400L X 500W X 1700H",
  Material:"Stainless steel pipe",
  imagePath:
  "assets/Workstations/(d7) Stainless Steel Pipe Nut Bolt Joint Table.jpg",
  },
  ],
  },
  {
  id: 44,
  name: "Mild Steel (MS) Pipe & Joint Workstation",
  productImage: [
  {
  id: "d8",
  name: "Workstation Assembled By Nut Bolt",
  des:"Workstation assembled by nut bolts are fully customisable, these workstations can be quickly designed and assembled for handling and storage equipment it offers a simple, space-saving solution. They are cut to length and connected using a selection of steel interlocking joints and accessories. These nut bolt joint workstations are versatile and economical, all pipes and joints are reusable so constructions can easily be reconfigured or products recycled.",
  size:"1200L X 700W X 2000H",
  Material:"Mild steel pipe (dia 28 o.d.)",
  imagePath:
  "assets/Workstations/(d8) Workstation Assembled By Nut Bolt.jpg",
  },
  ],
  },
  {
  id: 45,
  name: "Mild Steel Slotted Angle Workstation",
  productImage: [
  {
  id: "d9",
  name: "MS Working Table Assembled By Nut Bolt",
  des:"Mild steel (M.S.) working tables are made with box pipes joined with nut bolts by different operations like drilling, cutting, bending, tapping, etc.. which are suitable to give the desired shape.Mild steel (M.S.) working tables can be supplied in different colours and wall thicknesses as per application requirements. This working table has 1 notice board, 1 tube light, 1 shelf & 2 sockets which are removable if anything is not required.",
  size:"1400L X 500W X 1800H",
  Material:"Mild steel pipe",
  imagePath:
  "assets/Workstations/(d9) MS Working Table Assembled By Nut Bolt.jpg",
  },
  {
  id: "d10",
  name: "MS Workstation Assemble With Nut Bolt",
  des:"Mild steel (M.S.) workstations are made with box pipes joined with nut bolts by different operations like drilling, cutting, bending, tapping, etc.. which are suitable to give the desired shape. This M.S. workstation has 1 shelf which is around 450mm above the table top.",
  size:"800L X 500W X 1350H",
  Material:"Mild steel pipe",
  imagePath:
  "assets/Workstations/(d10) MS Workstation Assemble With Nut Bolt.jpg",
  },
  ],
  },
  {
  id: 46,
  name: "ABS (plastic) Coated Pipe & Joint Workstation",
  productImage: [
  {
  id: "d11",
  name: "Plastic (ABS) Coated Pipe Joint Workstation",
  des:"Plastic (ABS) Coated Pipe Joint Workstations are assembled with ABS coated pipe from outside & anti rust coated from inside. This ABS plastic coated pipe comes in various colours with different wall thicknesses  which is sufficient to bear weight up to 200kg maximum per square metre. This workstation has some placons fixed with clamps on the table to store boxes or plastic bins to make material handling easier.",
  size:"1200L X 700W X 2000H",
  Material:"Goblin pipe (ABS coated pipe)",
  imagePath:
  "assets/Workstations/(d11) Plastic (ABS) Coated Pipe Joint Workstation.jpg",
  },
  ],
  },
  ],
  },
  {
  id: 5,
  name: "Nut Bolt Racks",
  des:"Racks are assembled by nut & bolt with different pipes, angles , profiles, joints , accessories of different materials made by plastics , aluminium, stainless steel, mild steel to complete application suitable for customers. Racks are helpful for space management in all places & easy to disassemble if you need to shift.",
  imagePath: "assets/NutBoltRacks/(e1) Aluminium Profile 40 40  Rack.jpg",
  subcategories: [
  {
  id: 51,
  name: "Aluminium Profile 40x40 Rack",
  productImage: [
  {
  id: "e1",
  name: "Aluminium Profile 40 40  Rack",
  des:"Aluminium profile 40x40 rack is an important tool for improving the efficiency of modern warehouses. The shelf is a storage facility for storing items. The aluminium profile system can be used to assemble various storage shelves. And its main features are flexibility and expandability.Aluminium profile 40x40 racks are easy to install; no machining required; neat and tidy.Good mechanical properties, high connection strength, and high bearing capacity.The surface is beautiful and corrosion resistant.Easy assembly, modular multi-function and without complicated design.Higher expandability, optionally add components and simple modification",
  shelves:"3 nos",
  racksize:"000L x 400W x 1650H",
  Material:"luminium profile 40 40",
  imagePath:
  "assets/NutBoltRacks/(e1) Aluminium Profile 40 40  Rack.jpg",
  },
  {
  id: "e2",
  name: "Aluminium Rack 40x40 Profile",
  des:"The Aluminium 40 40 profile rack is assembled by an aluminium extrusion profile with T Slot Nut bolts in any desired shape. The aluminium profile system can be used to assemble various storage shelves. And its main features are flexibility and expandability.Aluminium profile 40 40 racks are easy to install; no machining required; neat and tidy.",
  size:"1500L x 400W x 1300H",
  profile:"Aluminium profile 40 40",
  imagePath:
  "assets/NutBoltRacks/(e2) Aluminium Rack 40x40 Profile.jpg",
  },
  ],
  },
  {
  id: 52,
  name: "Aluminium Profile 30x30 Rack",
  productImage: [
  {
  id: "e3",
  name: "Aluminium 30 30 Profile Rack",
  des:"The Aluminium 30x30 profile rack is assembled by an aluminium extrusion profile with T Slot Nut bolts in any desired shape. Aluminium profile 30x30 racks are easy to install; no machining required; neat and tidy.Good mechanical properties, high connection strength, and high bearing capacity.The surface is beautiful and corrosion resistant. Easy assembly, modular, multi-function and without complicated design.Higher expandability, optionally add components and simple modification",
  imagePath:
  "assets/NutBoltRacks/(e3) Aluminium 30 30 Profile Rack.jpg",
  },
  ],
  },
  {
  id: 53,
  name: "Aluminium Pipe & Joint Rack",
  productImage: [
  {
  id: "e4",
  name: "Aluminium Pipe Joint Rack",
  des:"The aluminium pipe joint racking system is simple to adjust as well as allow easy handling of products that are arranged on them. Further, as the installation material is light in properties, it does not require the special facility to manipulate. Offering high usage safety, aluminium 40x40 profile is also simple in fitting in work places and does not require welding of joints.These aluminium pipe joint racks are easy to assemble and can be easily completed by fitting together pipes and joints using just the hands. Further, these pipes are connected with standard joints and are also very simple as well as fast for assembling and knocking down. Ensuring a safe working environment for workers, users can also adjust the heights of these aluminium pipe racks as per the requirements.",
  imagePath: "assets/NutBoltRacks/(e4) Aluminium Pipe Joint Rack.jpg",
  },
  ],
  },
  {
  id: 54,
  name: "Stainless Steel Nut Bolt Rack",
  productImage: [
  {
  id: "e5",
  name: "Stainless Steel Rack",
  des:"Powder coated stainless steel shelves are made with box pipes joined with nut bolts by different operations like drilling, cutting, bending, tapping, etc.. which are suitable to give the desired shape.Sturdy and strong black powder coated steel shelving. This steel shelving would be perfect for a range of applications, including heavy or light parts storage in your warehouse. Going by their durability, you will find that stainless steel shelves are far more cost-effective in the long term than any other shelving option.",
  imagePath: "assets/NutBoltRacks/(e5) Stainless Steel Rack.jpg",
  },
  ],
  },
  {
  id: 55,
  name: "Stainless Steel (SS) Pipe Joint Rack",
  productImage: [
  {
  id: "e6",
  name: "SS Pipe Joint Nut Bolt Rack",
  des:"SS pipe joint nut bolt racks are easy to assemble and can be easily completed by fitting together pipes and joints using just the hands. The shelves can be expanded easily if need be in the future. It helps in utilising the storage area to the maximum extent possible. Individual expansion of shelves is possible.This stainless steel (ss) pipe joint rack system is so flexible that it can be made into various types of racks, trolleys, workstations, gravity flow racks & even light duty machine structures.",
  shelves:"4 nos",
  size:"2000L x 500W x 1800H",
  Material:"Stainless steel pipe with sunpack sheet blue colour",
  imagePath:
  "assets/NutBoltRacks/(e6) SS Pipe Joint Nut Bolt Rack.jpg",
  },
  {
  id: "e7",
  name: "SS Pipe Joint Assemble Rack",
  des:"Stainless steel pipe joint assembly racks are easy in the installation process, quick and simple. Customized to suit the retrieving, storage and economic requirements Space saving racks that fully utilizes floor space by easily customizing racks Rapid assembly of racks are easy.  Racks can be easily re-constructed again and again easily by just dis-assembling the pipes and replacing them with new ones. The wide variety of available joints make the system flexible to be quickly moulded into any shaped bends making the system suitable for a variety of lean systems.",
  shelves:"5 nos both side",
  size :"1100L x 900W x 1400H",
  Material:"Stainless steel pipe with acrylic sheet",
  imagePath:
  "assets/NutBoltRacks/(e7) SS Pipe Joint Assemble Rack.jpg",
  },
  ],
  },
  {
  id: 56,
  name: "Mild Steel (MS) Nut Bolt Rack",
  productImage: [
  {
  id: "e8",
  name: "MS Nut Bolt Rack",
  des:"Mild steel nut bolt shelving is a simple and cost effective option that is well suited for any kind of storage requirement. The slotted M.S. angle uprights have a unique pre-punched hole pattern that holds bolted multiple steel sections thereby offering a versatile, economic and sturdy shelving system.",
  shelves:"4 nos",
  size:"900L x 300W x 1800H",
  Material:"mild steel (M.S.) angle",
  imagePath: "assets/NutBoltRacks/(e8) MS Nut Bolt Rack.jpg",
  },
  ],
  },
  {
  id: 57,
  name: "Mild Steel (MS) Powder Coated Pipe Joint Rack",
  productImage: [
  {
  id: "e9",
  name: "Powder Coated Pipe Nut Bolt Joint Rack",
  des:"Powder Coated Pipe Nut Bolt Joint Racks are assembled by nut bolts & black metal joints. Powder coated sheet or any other sheet can be fixed to cover the rack from all sides to prevent material from dust & water which is kept inside the rack. The door with handles, hinges & lock can also be fixed in front of the rack for the material safety.This powder coated rack can be disassembled easily if needed.Powder coating shelves increase the durability of the steel and help the equipment withstand heavy loads and collision damage. Moisture and humidity can make metal rust. A powder coated sheet creates a barrier that helps prevent corrosion.",
  shelves:"3 nos",
  size:"450L x 400W x 1200H",
  Material:"Mild steel (M.S.) powder coated",
  imagePath:
  "assets/NutBoltRacks/(e9) Powder Coated Pipe Nut Bolt Joint Rack.jpg",
  },
  {
  id: "e10",
  name: "MS Powder Coated Pipe Joint Rack",
  des:"MS (mild steel) powder coated pipe joint racks are the most economical solution for scratch resistant surfaces. It can be supplied in different colours and wall thicknesses. An efficient rust inhibitor is used on the inside of mild steel pipes. Powder coated sheet or any other sheet can be fixed to cover the rack from the back sides to prevent material from falling backwards.",
  shelves:"8 nos total",
  size:"2100L x  500W x 1500H",
  Material:"Mild steel powder coated",
  imagePath:
  "assets/NutBoltRacks/(e10) MS Powder Coated Pipe Joint Rack.jpg",
  },
  ],
  },
  {
  id: 58,
  name: "Plastic Bins Storage Racks",
  productImage: [
  {
  id: "e11",
  name: "Plastic Bins Storage Rack",
  des:"Plastic storage bins are available in a wide selection of different sizes and styles, so it Improves space efficiency when it is placed inside pipe joint rack shelves whose dimensions are made according to the size of plastic bins. Plastic bins storage rack improves stock management If you keep a lot of smaller items in stock, such as nails and screws, plastic storage bins can make it easier to organize, find, and identify items, which can help prevent out-of-stock issues",
  shelves:"5 nos.",
  Material:"ABS coated on mild steel pipe",
  imagePath:
  "assets/NutBoltRacks/(e11) Plastic Bins Storage Rack.jpg",
  },
  {
  id: "e12",
  name: "Plastic Bins Nut Bolt Rack",
  des:"This plastic bins nut bolt rack is assembled by ABS coated pipe which is coated with a layer of ABS resin that helps in providing desired protection to pipes from corrosion. Further, these are also cost effective as all pipes and joints are re-usable.Space saving racks that fully utilizes floor space by easily customizing racks. Rapid assembly of racks are easy. Racks can be easily re-constructed again and again easy to maintain by just dis-assembling the pipes and replacing them with new ones.Plastic bins nut bolt rack offers a Long-Lasting Storage Solution are Unlike cardboard boxes and wooden storage units, plastic storage containers and bins provide a long-lasting storage solution that will not be as easily affected by external elements. Plastic storage bins are made from durable, lightweight, and high-density materials that are waterproof and UV resistant.",
  shelves:"5 nos",
  size:"2400L x 600W x 1400H",
  Material:"Goblin pipe (ABS coated on mild steel pipe)",
  imagePath:
  "assets/NutBoltRacks/(e12) Plastic Bins Nut Bolt Rack.jpg",
  },
  ],
  },
  ],
  },
  {
  id: 6,
  name: "Fabricated Racks",
  des:"Fabricated racks are made by welding of pipes, angles, sheets, etc. of solid material like stainless steel or mild steel mainly suitable to store heavy items like heavy bins, dies , machines , etc. because of its welded frame fabricated racks are more attractive then nut bolt racks so it looks beautiful in places like hospitals, malls , hotels , etc. Fabricated racks are also not easy to breakdown by sudden fall or slip of heavy material from height. It's disassemble is very difficult & costly.",
  imagePath: "assets/FabricatedRacks/(f1) Mild Steel Wiremesh Trolley.jpg",
  subcategories: [
  {
  id: 61,
  name: "Mild Steel (MS) Wiremesh Rack",
  productImage: [
  {
  id: "f1",
  name: "Mild Steel Wiremesh Trolley",
  des:"Mild steel wiremesh trolleys are widely used in a number of industrial and commercial places for arranging objects in an organized manner and conveying it from one distance to another safely. Mild steel mesh is used on all 4 sides, and the wheels use standard radial bearings. The load platform, handle, and wire mesh are made of mild steel.",
  size :"1000L x 600W x 900H",
  Material:"Mild steel powder coated",
  imagePath:
  "assets/FabricatedRacks/(f1) Mild Steel Wiremesh Trolley.jpg",
  },
  ],
  },
  {
  id: 62,
  name: "Stainless Steel (SS) Wiremesh Rack",
  productImage: [
  {
  id: "f2",
  name: "SS Wiremesh Trolley",
  des:"A versatile and durable material, stainless steel wire mesh trolleys are used in most industries, including chemicals, pharmaceuticals, plastics, mechanical engineering, mining and food processing. A trolley that’s lightweight and moves quietly. Stainless steel mesh is used on all 4 sides, and the wheels use standard radial bearings. Loads with many small parts can be pushed for transportation. The load platform, handle, and wire mesh are made of stainless steel.",
  size :"750L x 600W x 900H",
  Material:"Stainless steel",
  imagePath: "assets/FabricatedRacks/(f2) SS Wiremesh Trolley.jpg",
  },
  {
  id: "f3",
  name: "Stainless Steel Fabricated Rack",
  des:"There is no doubt that stainless steel fabricated racks are among the most reliable and durable for commercial equipment and food services. These accessories are modern in all types of automobile industries, hospitals, hotels and restaurants. Their nature makes them safe, and shortly.The first of these advantages is that they come in different grades which allow users to select the one that they find most convenient. These stainless steel fabricated racks are widely used in various industries which are manufactured in different specifications as per application requirements.",
  size:"800L x 800W x 900H",
  Material:"Stainless steel",
  imagePath:
  "assets/FabricatedRacks/(f3) Stainless Steel Fabricated Rack.jpg",
  },
  ],
  },
  ],
  },
  {
  id: 7,
  name: "Nut Bolt Trolleys",
  des:"Nut Bolt Trolleys are used to move material from one place to another with the help of casters & it's assembled by nut & bolt with different pipes, angles , profiles, joints , accessories of different materials made by plastics , aluminium, stainless steel, mild steel to complete application suitable for customers. Nut bolt Trolleys are easy to disassemble if it needs any change.",
  imagePath:
  "assets/NutBoltTrolleys/(g1) Aluminium Profile 40 40 Trolley.jpg",
  subcategories: [
  {
  id: 71,
  name: "Aluminium Profile 40x40 Trolley ",
  productImage: [
  {
  id: "g1",
  name: "Aluminium Profile 40x40 Trolley",
  des:"Aluminium profile 40x40 trolleys are made of such light materials that the handling of the product becomes very easy. For the purposes of producing also, it is just not a mandate to equip special facilities. It is even highly protective and an accommodating fit at all the workplaces. These aluminium profile 40x40 trolleys are designed for clever connection elements without machines and equipment. The profiles are cut to length and assembled.",
  size:"1500L X 700W X 1500H",
  shelves:"3 nos.",
  Material :"Aluminium profile with wooden board",
  imagePath:
  "assets/NutBoltTrolleys/(g1) Aluminium Profile 40 40 Trolley.jpg",
  },
  ],
  },
  {
  id: 72,
  name: "Aluminium Pipe Joint Trolley",
  productImage: [
  {
  id: "g2",
  name: "Aluminium Pipe Joint Trolley",
  des:"Aluminium pipe joint trolleys are fabricated by using the highest quality raw materials and advanced technologies in order to provide excellent structural support and incredible durability.Aluminium pipe joint trolleys have a smooth surface finish & light in weight and allow easy installation. The offered product is extremely durable and available for customisation in various specifications as per requirements at cost efficient rates.",
  size:"900L X 900W X 1100H",
  shelves:"3 nos",
  Material:"Aluminium pipe dia 28 with placons 40 type",
  imagePath:
  "assets/NutBoltTrolleys/(g2) Aluminium Pipe Joint Trolley.jpg",
  },
  ],
  },
  {
  id: 73,
  name: "Mild Steel (MS) Pipe Joint Trolley",
  productImage: [
  {
  id: "g3",
  name: "MS Pipe Joint Nut Bolt Trolley",
  des:"M.S. mild steel powder coated pipe joint nut bolt trolleys are the most economical solution for storage & transport material from one place to another. Nut bolt trolleys can be supplied in different colours and wall thicknesses. An efficient rust inhibitor is used on the inside.The pipe joint system is so flexible that it can be made into various types of racks, trolleys, workstations, gravity flow racks & even light duty machine structures. The wide variety of available joints make the system flexible to be quickly moulded into any shaped bends making the system suitable for a variety of lean systems.",
  size:"1200L X 500W X 1400",
  shelves:"5 nos.",
  Material:"Mild Steel powder coated pipe with plastic sheet",
  imagePath:
  "assets/NutBoltTrolleys/(g3) MS Pipe Joint Nut Bolt Trolley.jpg",
  },
  {
  id: "g4",
  name: "MS Powder Coated Pipe Nut Bolt Trolley",
  des:"M.S. mild steel powder coated pipe joint nut bolt trolleys are widely useful in warehouses, automobile industries, hotels, airports , shopping malls, etc. Nut bolt trolleys can be supplied in different colours and wall thicknesses. An efficient rust inhibitor is used on the inside. Fully customisable, the system can be quickly designed and assembled for handling and storage equipment it offers a simple, space-saving solution. Mild steel powder coated pipes are cut to length and connected using a selection of steel interlocking joints and accessories.The pipe joint system is so flexible that it can be made into different types of racks, trolleys, workstations, gravity flow racks & even light duty machine structures.",
  shelves:"2 nos",
  size:"700L x 400W x 850H",
  Material:"Mild steel pipe & wooden board",
  imagePath:
  "assets/NutBoltTrolleys/(g4) MS Powder Coated Pipe Nut Bolt Trolley.jpg",
  },
  ],
  },
  {
  id: 74,
  name: "Stainless Steel (SS) Pipe Joint Trolley",
  productImage: [
  {
  id: "g5",
  name: "SS Pipe Joint Nut Bolt Trolley",
  des:"Stainless steel pipe joint nut bolt trolleys are more durable than plastic coated pipe trolleys where aggressive chemicals used which could affect plastic coatings.Pipe and joint system is a material storage & handling solution, used worldwide by replacing the systems of welded constructions. Pipe and joint system a simple product with endless possibilities. Fully customisable, the system can be quickly designed and assembled for handling and storage equipment it offers a simple, space-saving solution.Stainless steel pipes are cut to length and connected using a selection of steel interlocking joints and accessories.The system is versatile and economical, all pipes and joints are reusable so constructions can easily be reconfigured or products recycled.",
  shelves:"3 nos.",
  size:"1000L X 400W X 1100H",
  Material:"Stainless steel (ss) pipe with acrylic sheet",
  imagePath:
  "assets/NutBoltTrolleys/(g5) SS Pipe Joint Nut Bolt Trolley.jpg",
  },
  {
  id: "g6",
  name: "Steel Pipe Joint Trolley",
  des:"Stainless steel pipe joint nut bolt trolleys are assembled for handling and storage equipment. Stainless steel pipes are cut to length and connected using a selection of steel interlocking joints and accessories. Stainless steel pipe joint nut bolt trolleys are used worldwide by replacing the systems of welded constructions which have many operations to finalize the product. Pipe and joint system a simple product with endless possibilities",
  shelves :"3 nos",
  size:"800L X 400W X 900H",
  Material:"Stainless steel pipe with plastic sheet",
  imagePath:
  "assets/NutBoltTrolleys/(g6) Steel Pipe Joint Trolley.jpg",
  },
  ],
  },
  {
  id: 75,
  name: "ABS (Plastic) Coated Pipe & Joint Trolley",
  productImage: [
  {
  id: "g7",
  name: "Plastic (ABS) Coated Pipe Joint Nut Bolt Trolley",
  des:"Plastic (ABS) coated pipe joint nut bolt trolleys are assembled by plastic coated pipe with various types of metal joints which helps to work according to frame requirements. Plastic coated ABS pipe surface treated then an adhesive is applied to fix the virgin ABS plastic coating. An efficient corrosion inhibitor is applied to the inside of the pipe. Plastic (ABS) coated pipe joint nut bolt trolley is an ideal system to apply to operations where Kaizen (Continuous Improvement) and Lean Manufacturing concepts are applied.",
  shelves:"3 nos",
  size:"600L X 450W X 850H",
  Material:"Goblin pipe (Plastic ABS coated Pipe) with wooden board",
  imagePath:
  "assets/NutBoltTrolleys/(g7) Plastic (ABS) Coated Pipe Joint Nut Bolt Trolley.jpg",
  },
  {
  id: "g8",
  name: "Plastic Coated Steel Pipe Joint Trolley",
  des:"Plastic coated steel pipe joint trolleys can be adjusted according to the size to meet your needs. Very easy to adjust the height or the distance between the layers, just need to loosen the bolt and nut and move the ABS coated pipe and pipe joints to the suitable place and tighten the screw nut. These plastic coated steel pipe joint trolleys are very useful to store plastic bins & cartons. The weight capacity of this trolley is approximately 200kg each shelf.",
  shelves:"3 nos",
  size:"800L X 400W X 1000H",
  Material:"Goblin pipe (ABS pipe) with placons 40 type",
  imagePath:
  "assets/NutBoltTrolleys/(g8) Plastic Coated Steel Pipe Joint Trolley.jpg",
  },
  ],
  },
  {
  id: 76,
  name: "Material Handling Trolley",
  productImage: [
  {
  id: "g9",
  name: "Plastic Bins Trolley",
  des:"A plastic bins trolley is an important tool for improving the efficiency of modern warehouses. The plastic bin is a storage facility for storing items. The pipe and joint system can be used to assemble various storage shelves. And its main features are flexibility and expandability.Plastic storage bins are available in a wide selection of different sizes and styles, so it Improves space efficiency when it is placed inside plastic bins trolley shelves whose dimensions are calculated according to the size of plastic bins. Plastic bins trolley improves stock management If you keep a lot of smaller items in stock, such as nails and screws, plastic storage bins can make it easier to organize, find, and identify items, which can help prevent out-of-stock issues.Plastic bins trolleys can be readily configured to suit your specific application.",
  shelves:"4 nos.",
  size:"1100L X 1000W X 1400H",
  Material:"Goblin pipe (plastic coated pipe) with plastic bins",
  imagePath: "assets/NutBoltTrolleys/(g9) Plastic Bins Trolley.jpg",
  },
  {
  id: "g10",
  name: "Material Handling Nut Bolt Trolley",
  des:"This material handling nut bolt trolley is assembled by pipe and joint system which is an advanced logistics system which can be assembled by 28 mm OD pipes, with or without plastic coatings. The thickness of the pipes generally varies between 1 and 2 mm, providing various solidity levels which can be adapted to the project’s specifications. The metal joints and various fasteners also contribute to the strength of the frame. This material handling nut bolt trolley has some plastic storage bins which are available in a wide selection of different sizes and styles, so it Improves space efficiency when it is placed inside plastic bins trolley shelves whose dimensions are calculated according to the size of plastic bins. This trolley is worked according to FIFO (first in first out) system which is very essential for advanced logistics.",
  shelves:"3 nos.",
  size:"1800L X 900W X 1400H",
  Material:"Goblin pipe (plastic coated pipe) with plastic bins",
  imagePath:
  "assets/NutBoltTrolleys/(g10) Material Handling Nut Bolt Trolley.jpg",
  },
  {
  id: "g11",
  name: "Nut Bolt Material Handling Trolley",
  des:"Nut bolt material handling trolley is made by 28 dia plastic coated pipe assembled with nut bolt joint. This trolley needs some operations like punching, sheet cutting, sheet bending, etc. as per application requirement.A wide range of accessories can enable your application to be customised.",
  size:"800L X 400W X 1200H",
  Material:"Mild steel pipe with sun pack sheet",
  imagePath:
  "assets/NutBoltTrolleys/(g11) Nut Bolt Material Handling Trolley.jpg",
  },
  ],
  },
  ],
  },
  {
  id: 8,
  name: "Fabricated Trolleys",
  imagePath:
  "assets/FabricatedTrolleys/(h1) Stainless Steel Multipurpose Trolley.jpg",
  subcategories: [
  {
  id: 81,
  name: "Stainless Steel (SS) Trolley",
  productImage: [
  {
  id: "h1",
  name: "Stainless Steel Multipurpose Trolley",
  des:"This stainless steel multipurpose trolley is made up of high quality steel & is rust proof. Stainless steel trolleys are the best when it comes to transporting a range of products say medicines, medical aids, culinary dishes, and cutlery in a sure, safe and clean way. Besides, these trolleys are a safer alternative in food manufacturing and pharmaceutical plants as they do not easily leach or corrode. It provides an efficient & good storage solution. Besides anti-corrosive property, they possess anti-bacterial features, thus making them safe and hygienic to use at all times. For this reason, stainless steel trolleys are the best choice in food manufacturing industries, restaurants, and hospitals.",
  shelves:"3 nos.",
  size:"800L X 400W X 900H",
  Material:"Stainless steel",
  imagePath:
  "assets/FabricatedTrolleys/(h1) Stainless Steel Multipurpose Trolley.jpg",
  },
  {
  id: "h2",
  name: "SS Fabricated Table",
  des:"Selecting the perfect table for your needs is important and can be a difficult decision. If you are looking for a sturdy table that will not rust or stain, a stainless steel fabricated table is a great option. They are multi-purpose and versatile and have a shiny and polished finish. These fabricated tables are easy to clean when compared to trolleys made of other materials such as wood and plastic.This stainless steel table is widely used in various industries. These entire products are manufactured in different specifications as per application requirements.",
  shelves:"1 table top with 2 shelves",
  size:"1400L X 500W X 1800H",
  Material:"Stainless steel",
  imagePath: "assets/FabricatedTrolleys/(h2) SS Fabricated Table.jpg",
  },
  {
  id: "h3",
  name: "Stainless Steel Service Trolley",
  des:"This stainless steel service trolley is made up of high quality steel & is rust proof. Stainless steel trolleys are the best when it comes to transporting a range of products say medicines, medical aids, culinary dishes, and cutlery in a sure, safe and clean way. The guide pipe is fabricated in this trolley to protect material from falling down. 4 wheels with thread cutting is fixed on the bottom inside all 4 legs. Besides, these trolleys are a safer alternative in food manufacturing and pharmaceutical plants as they do not easily leach or corrode. Thus, stainless steel service trolleys are the best choice in food manufacturing industries, restaurants, and hospitals.",
  shelves:"2 nos.",
  size:"600L X 400W X 800H",
  Material:"Stainless steel",
  imagePath:
  "assets/FabricatedTrolleys/(h3) Stainless Steel Service Trolley.jpg",
  },
  ],
  },
  {
  id: 82,
  name: "Mild Steel (MS) Trolley",
  productImage: [
  {
  id: "h7",
  name: "MS Wiremesh Trolley",
  des:"Mild steel wire mesh trolleys are made up of m.s. wire net, steel, angles, flats, pipes, etc... Mesh construction for open air , see thru use. All welded construction for long lasting use.  Extra mobility thanks to 2 swivel, 2 rigit heavy duty casters. Durable powder coat finish. MS wire mesh trolleys are widely used in the number of industrial and commercial places for arranging objects in an organized manner and conveying it from one distance to another safely.",
  size:"1000L X 600W X 900H",
  Material:"Mild steel",
  imagePath: "assets/FabricatedTrolleys/(h7) MS Wiremesh Trolley.jpg",
  },
  {
  id: "h8",
  name: "Mild Steel Fabricated Trolley",
  des:"Mild steel fabricated trolleys are made up of mild steel pipes, flats, bends, sheets, nut bolts & casters. This trolley is fabricated according to transfer of heavy or light weight material. Mild steel trolleys are widely used in automobile industries, hotels, hospitals, shopping malls, etc. These entire products are manufactured in different specifications as per application requirements.",
  size:"750L X 500W X 900H",
  Material:"Mild steel",
  imagePath:
  "assets/FabricatedTrolleys/(h8) Mild Steel Fabricated Trolley.jpg",
  },
  {
  id: "h9",
  name: "MS Pipe Trolley",
  des:"MS pipe trolleys are made from steel tubing, rods on sides & back. Powder coat finish. These mild steel trolleys are widely used for transporting bulky packages, boxes, supplies, parts for transfer & more. It is easy to handle & move. Mild steel pipe trolleys are specially fabricated to move heavy weight material.",
  shelves : "4 nos.",
  size :"1000L X 700W X 1600H",
  Material:"Mild steel",
  imagePath: "assets/FabricatedTrolleys/(h9) MS Pipe Trolley.jpg",
  },
  ],
  },
  ],
  },
  {
  id: 9,
  name: "Stands",
  imagePath: "assets/Stands/(i1) Aluminium 30 30 Profile Stand.jpg",
  subcategories: [
  {
  id: 91,
  name: "Aluminium 30 30 Profile Stand",
  productImage: [
  {
  id: "i1",
  name: "Aluminium 30 30 Profile Stand",
  des:"Aluminium 30x30 profile stand is most commonly used across industrial automation applications such as automated motion applications on process lines and industries throughout manufacturing. Aluminium 30x30 profile is usually considered a lightweight material but it is in fact extremely strong and versatile.",
  size :"500L X 600W X 800H",
  Material:"Aluminium Profile 30x30",
  imagePath: "assets/Stands/(i1) Aluminium 30 30 Profile Stand.jpg",
  },
  {
  id: "i2",
  name: "Aluminium 30 30 Frame",
  des:"Aluminium 30x30 frame is a light weight material but it is extremely strong & versatile. Aluminium 30 30 Profile has a longer life than items made from steel or plastic. Besides, extrusion is meant to be the most environmentally friendly and economical solution. Aluminium 30 30 frame is joined by various accessories like aluminium bracket, plastic strip, height adjusters, plastic end cap, t-nut bolt, etc. which are specially made according to aluminium 30 30 profile dimensions.",
  size:"800L X 500W X 600H",
  Material:"Aluminium profile with wooden board",
  imagePath: "assets/Stands/(i2) Aluminium 30 30 Frame.jpg",
  },
  ],
  },
  {
  id: 92,
  name: "Aluminium 40 40 Profile Stand",
  productImage: [
  {
  id: "i3",
  name: "Aluminium Extrusion Shelf Stand",
  des:"Aluminium extrusion shelf stands are made by 40x40 aluminium profiles assembled with aluminium brackets & t-nut bolts. This aluminium stand has 4 casters with brakes which are selected according to weight & surface material on which it will move. Stainless steel shelves help to keep materials safe from corrosion.",
  size:"1080L X 500W X 750H",
  Material:"Aluminium profile with stainless steel sheet",
  imagePath: "assets/Stands/(i3) Aluminium Extrusion Shelf Stand.jpg",
  },
  {
  id: "i4",
  name: "Aluminium Profile 40 40 Stand",
  des:"Aluminium profile 40x40 stands are an important tool for improving the efficiency of modern warehouses. The shelf is a storage facility for storing items. The aluminium profile system can be used to assemble various storage shelves. And its main features are flexibility and expandability.Aluminium profile 40x40 stands are easy to install; no machining required; neat and tidy.",
  size:"1200L X 700W X 1000H",
  Material:"Aluminium Profile 40x40",
  imagePath: "assets/Stands/(i4) Aluminium Profile 40 40 Stand.jpg",
  },
  ],
  },
  {
  id: 93,
  name: "Aluminium Pipe Joint Stand",
  productImage: [
  {
  id: "i5",
  name: "Aluminium Pipe Joint Stand",
  des:"Aluminium pipe joint stands are widely used in automobile industries because of its various advantages like light weight, weldless, easy assembly and reforming activities are possible in a continuous fashion providing scope for modification. Dia 28 mm aluminium pipe is used in this stand which is assembled with metal joints by nut bolts . These aluminium pipe joint stands are designed for clever connection elements without machines and equipment. The profiles are cut to length and assembled with metal joints.",
  size:"2000L X 600W X 2000H",
  Material:"Aluminium pipe dia 28mm",
  imagePath: "assets/Stands/(i5) Aluminium Pipe Joint Stand.jpg",
  },
  ],
  },
  {
  id: 94,
  name: "S.S. Pipe Joint & Nut Bolt Stand",
  productImage: [
  {
  id: "i6",
  name: "Stainless Steel Pipe Joint Nut Bolt Stand",
  des:"Stainless steel pipe joint nut bolt stands are used to display & storage material for warehouses, hotels, airports, hospitals, malls, etc. Stainless Steel pipe joint stands are made up of stainless steel pipe with various accessories like joints, nut bolts, height adjusters, plastic caps, name plates, etc..These stands are multi-purpose and versatile and have a shiny and polished finish. Stainless steel pipes stands are easy to clean when compared to stands made of other materials such as wood and plastic.",
  size:"1600L X 500W X 1800H",
  Material:"Stainless steel pipe",
  imagePath:
  "assets/Stands/(i6) Stainless Steel Pipe Joint Nut Bolt Stand.jpg",
  },
  ],
  },
  {
  id: 95,
  name: "M.S. Pipe Joint & Nut Bolt Stand",
  productImage: [
  {
  id: "i7",
  name: "MS Pipe Stand Assemble By Nut Bolt",
  des:"MS pipe stands assembled by nut bolts are assembled with mild steel (M.S.) powder coated pipe joints with nut bolts to build up a strong frame. This pipe and joint system can be easily modified If suddenly the application is changed. A wooden top is given to handle materials easily which are required repeatedly. This M.S. stand can be used in multiple ways in industries.",
  size:"800L X 500W X 1500H",
  Material:"Mild steel pipe with wooden top",
  imagePath:
  "assets/Stands/(i7) MS Pipe Stand Assemble By Nut Bolt.jpg",
  },
  ],
  },
  {
  id: 96,
  name: "Plastic Coated Pipe Joint Stand",
  productImage: [
  {
  id: "i8",
  name: "Plastic (ABS) Pipe Nut Bolt Stand",
  des:"Plastic (ABS) coated pipe nut bolt stands are assembled with ABS coated pipe from outside & anti rust coated from inside. This ABS plastic coated pipe comes in various colours with different wall thicknesses which is sufficient to bear weight up to 200kg maximum per square metre. This plastic coated pipe joint stand is simple to adjust as well as allow easy handling of products that are arranged on them. Further, as the installation material is light in properties, it does not require the special facility to manipulate. The pipe & joint system is simple in fitting in work places and does not require welding of joints.",
  size:"2400L X 500W X 2300H",
  Material:"Goblin pipe (ABS coated on steel pipe)",
  imagePath:
  "assets/Stands/(i8) Plastic (ABS) Pipe Nut Bolt Stand.jpg",
  },
  ],
  },
  {
  id: 97,
  name: "Carton Box Stand ",
  productImage: [
  {
  id: "i9",
  name: "Carton Box Nut Bolt Stand",
  des:"Carton box nut bolt stands can be adjusted according to the size to meet your needs. Name plates can be given above each row with the help of some accessories. These carton stands are very easy to adjust the height or the distance between the layers, just need to loosen the bolt and nut and move the ABS coated pipe and pipe joints to the suitable place and tighten the screw nut. These plastic coated steel pipe joint stands are very useful to store plastic bins & cartons. The weight capacity of this stand is approximately 200kg per square metre.",
  shelves:"4 nos.",
  size:"4000L X 2000W X 1600H",
  Material:"Goblin Pipe (ABS coated pipe)",
  imagePath: "assets/Stands/(i9) Carton Box Nut Bolt Stand.jpg",
  },
  ],
  },
  ],
  },
  {
  id: 10,
  name: "Notice Boards",
  imagePath:
  "assets/NoticeBoards/(j1) Aluminium Profile Heavy Notice Board.jpg",
  subcategories: [
  {
  id: 101,
  name: "Aluminium Profile Notice Board",
  productImage: [
  {
  id: "j1",
  name: "Aluminium Profile Heavy Notice Board",
  des:"Aluminium profile heavy notice board is  assembled by aluminium 40x40 profile with nut bolts & profile accessories. Besides, the pin up notice board is fixed between the grove of 40 40 profiles & from the centre the notice board is fixed with aluminium flat with some hidden screws. Caster can be assembled on the bottom if the notice board needs to shift repeatedly otherwise height adjusters or end cap can be used.",
  size:"1300L X 400W X 1800H",
  Material:"Aluminium profile",
  imagePath:
  "assets/NoticeBoards/(j1) Aluminium Profile Heavy Notice Board.jpg",
  },
  ],
  },
  {
  id: 102,
  name: "Aluminium Pipe Joint Notice Board",
  productImage: [
  {
  id: "j2",
  name: "Aluminium Pipe Joint Notice Board",
  des:"Aluminium pipe joint notice boards are assembled by cutting pieces of aluminium pipe with nut bolt joints. Besides, the pin up notice board is fixed with a stainless steel clamp or angle behind the board with nut bolts & screws. On the bottom height adjusters or casters can be fitted as per application requirement. The end pipe hole is covered with plastic end cap. This pipe & joint system is a good option for all those places where notice boards are required.",
  size:"1500L X 300W X 1600H",
  Material:"Aluminium pipe",
  imagePath:
  "assets/NoticeBoards/(j2) Aluminium Pipe Joint Notice Board.jpg",
  },
  ],
  },
  {
  id: 103,
  name: "S.S. Pipe Joint & Nut Bolt Notice Board",
  productImage: [
  {
  id: "j3",
  name: "Stainless Steel Pipe Joint Nut Bolt Notice Board",
  des:"Stainless steel pipe joint nut bolt notice boards make it very easy to adjust the height of the pin up notice board, just need to loosen the bolt and nut and move the notice board with stainless steel pipe and pipe joints to the suitable height and tighten the screw nut. Stainless steel pipe joint nut bolt pin up notice board height can also adjust by  height adjusters if required it can be fitted on the bottom of steel pipe or replace it with casters if it needs to move repeatedly.",
  size:"1500L X 350W X 1800H",
  Material:"Stainless steel pipe",
  imagePath:
  "assets/NoticeBoards/(j3) Stainless Steel Pipe Joint Nut Bolt Notice Board.jpg",
  },
  ],
  },
  {
  id: 104,
  name: "M.S. Pipe Joint & Nut Bolt Notice Board",
  productImage: [
  {
  id: "j4",
  name: "MS Powder Coated Pipe Dia 28 MM Nut Bolt Notice Board",
  des:"This mild steel powder coated pipe dia 28 mm nut bolt notice board is joined by m.s. pipes with metal joints. This is a writing board fixed with pipe joint clamps from behind & angles with screws & bolts. The pipe joint notice boards are widely used in industries & warehouses, hotels, hospitals, etc. This system can be modified easily with some simple steps.",
  size :"2000L X 500W X 1800H",
  Material:"Mild steel pipe",
  imagePath:
  "assets/NoticeBoards/(j4) MS Powder Coated Pipe Dia 28 MM Nut Bolt Notice Board.jpg",
  },
  ],
  },
  {
  id: 105,
  name: "Plastic Coated Pipe Joint Notice Board",
  productImage: [
  {
  id: "j5",
  name: "Plastic Coated Pipe Joint Notice Board",
  des:"Plastic coated pipe joint nut bolt notice boards make it very easy to adjust the height of the pin up notice board frame, just need to loosen the bolt and nut and move the notice board with stainless steel pipe and pipe joints to the suitable height and tighten the screw nut. ABS coated pipe which is coated with a layer of ABS resin that helps in providing desired protection to pipes from corrosion. Further, these are also cost effective as all pipes and joints are re-usable.",
  size:"1800L X 500W X 1500H",
  Material :"Goblin pipe (ABS coated pipe)",
  imagePath:
  "assets/NoticeBoards/(j5) Plastic Coated Pipe Joint Notice Board.jpg",
  },
  ],
  },
  ],
  },
  {
  id: 11,
  name: "Cubical Tables",
  imagePath: "assets/CubicalTables/(k1) Aluminium Cubical Table.jpg",
  subcategories: [
  {
  id: 111,
  name: "Aluminium Cubical Table",
  productImage: [
  {
  id: "k1",
  name: "Aluminium Cubical Table",
  des:"Aluminium cubical tables can be used to create a stylish internal space that employees will genuinely enjoy working in. You can separate areas and use different colour schemes and decorative touches within each space, creating a sense of individualism for each area of the office. Aluminium is a robust material that is easy to clean, so aluminium partitions can be used without placing a substantial maintenance burden on the business.",
  imagePath: "assets/CubicalTables/(k1) Aluminium Cubical Table.jpg",
  },
  {
  id: "k2",
  name: "Aluminium Cubical Office Table",
  des:"The main advantage of cubical office tables is to give employees private workspaces without building each one an office. Aluminium cubical office tables make less noise and fewer distractions make for improved productivity. By not knowing what’s going on in another cubicle, the employee can stay focused on their own tasks. This also helps to reduce stress levels.",
  imagePath:
  "assets/CubicalTables/(k2) Aluminium Cubical Office Table.jpg",
  },
  ],
  },
  {
  id: 112,
  name: "Mild Steel (MS) Cubical Table",
  productImage: [
  {
  id: "k3",
  name: "MS Office Nut Bolt Table",
  des:"If you need more private offices but don’t have the space or money to build them, affordable cubicles are a great alternative. You still get the benefits of having individual workspaces without having to build literal walls. This MS office table can be customised according to requirements & assembled with nut bolts & screws.",
  imagePath: "assets/CubicalTables/(k3) MS Office Nut Bolt Table.jpg",
  },
  {
  id: "k4",
  name: "Mild Steel Office Table",
  des:"These mild steel office tables are very useful in small offices where cubical tables can't take place. These tables are economical & don't require any special machinery to install. Mild steel office tables frame made by mild steel box pipes, strips, angles, nut bolts & screws. In this table we can add-on pin up notice board, writing notice board, foot rest, socket & switch.",
  imagePath: "assets/CubicalTables/(k4) Mild Steel Office Table.jpg",
  },
  {
  id: "k5",
  name: "MS Cubical Office Table",
  des:"Mild steel (M.S.) office tables are economical & don't require any special machinery to install. Mild steel office tables frame made by mild steel box pipes, strips, angles, nut bolts & screws. In this table we can add-on pin up notice board, writing notice board, foot rest, socket & switch. Providing your employees with their own office desk gives them a sense of privacy and ownership. Once again, improving employee satisfaction.",
  imagePath: "assets/CubicalTables/(k5) MS Cubical Office Table.jpg",
  },
  ],
  },
  ],
  },
  ];