import React, { useState, useEffect } from "react";
import "./Gallery.css";
import GalleryNavigation from "./GalleryNavigation";
import GalleryContentArea from "./GalleryContentArea";
import { stockData } from "../../data";

function Gallery(props) {
  const [Data, setData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  useEffect(() => {
    const data = JSON.parse(JSON.stringify(stockData));
    setData(data);
    setSelectedCategory(data[0]);
    window.scrollTo(0, 0);
  }, []);
  const categorySelection = (category) => {
    setSelectedCategory(category);
    window.scrollTo(0, 0);
  };

  return (
    <div className="Gallery">
      <div className="banner">
        <h2>{selectedCategory.name}</h2>
      </div>

      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-md-3">
            <div className="gallery__Menu">
              <GalleryNavigation
                onCategoryData={categorySelection}
                productsData={Data}
              />
            </div>
          </div>
          <div className="col-md-9">
            <div className="gallery__Content">
              <GalleryContentArea category={selectedCategory} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
