import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "popper.js";

import { Routes, Route, HashRouter } from "react-router-dom";
import Home from "./components/home/Home";
import Header from "./components/layout/Header";
import Gallery from "./components/gallery/Gallery";
import Footer from "./components/layout/Footer";
import About from "./components/About/About";
import Contactus from "./components/Contact/Contactus";
import RequestQuote from "./components/RequestQuote/RequestQuote";
import ProductDetails from "./components/gallery/ProductDetails";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={
              <React.Fragment>
                <Header />
                <Home />
                <Footer />
              </React.Fragment>
            }
          ></Route>
          <Route
            path="/Gallery"
            element={
              <React.Fragment>
                <Header />
                <Gallery />
                <Footer />
              </React.Fragment>
            }
          ></Route>
          <Route
            path="/About"
            element={
              <React.Fragment>
                <Header />
                <About />
                <Footer />
              </React.Fragment>
            }
          ></Route>
          <Route
            path="/Contactus"
            element={
              <React.Fragment>
                <Header />
                <Contactus />
                <Footer />
              </React.Fragment>
            }
          ></Route>
          <Route
            path="/RequestQuote"
            element={
              <React.Fragment>
                <Header />
                <RequestQuote />
                <Footer />
              </React.Fragment>
            }
          ></Route>
          <Route
            path="/ProductDetails/:id"
            element={
              <React.Fragment>
                <Header />
                <ProductDetails />
                <Footer />
              </React.Fragment>
            }
          ></Route>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
