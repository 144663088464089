import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../logo3.png";
import phone from "../../phone.png";
import "./Header.css";

class Header extends React.Component {

  
  

  render(){
  return (
    <div className="Header">
      
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <Link to={"/"} className="navbar-brand">
          <img src={logo} className="header__logo" alt="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"

          
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse"  id="navbarNavDropdown">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <NavLink to={"/"} className="nav-link" data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation" activeclassname="active">
                Home <span className="sr-only">(current)</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={"/About"}
                className="nav-link"
                data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
                activeclassname="active"
              >
                About Us
              </NavLink>
            </li>
         
            <li className="nav-item">
              <NavLink
                to={"/Gallery"}
                className="nav-link"
                data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
                
                activeclassname="active"
                
              >
                Products / Gallery
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                to={"/Contactus"}
                className="nav-link"
                data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
                
                activeclassname="active"
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
          <div className="ml-auto social">
            <div className="mr-3 phone d-none d-md-block">
              <img
                src={phone}
                height="30px"
                className="mr-1"
                alt="phone__icon"
              ></img>
             <span className="mt-1 d-inline-block"> +91 98116 76770</span>
            </div>
            <a className="request__quote" href={process.env.PUBLIC_URL + '/catalogue.pdf'} download target="_blank">
             <i className="fa fa-arrow-down mr-2"></i> Download Catalogue
            </a>
          </div>
        </div>
        {/*<div className="mt-1 d-block d-sm-none d-md-none phone">
              <img
                src={phone}
                height="30px"
                className="mr-1"
                alt="phone__icon"
              ></img>
             <span className="mt-1 d-inline-block text-white" style={{"font-size":"22px"}}> +91 98116 76770</span>
  </div>*/}
        
      </nav>
      
    </div>
  );
  }
}

export default Header;
