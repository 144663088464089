import React from "react";
import "./WhyUs.css";
import bag from "../../shopping-bag.png";
import world from "../../world.png";
import cash from "../../cash.png";
import present from "../../present.png";

function WhyUs() {
  return (
    <div className="why__us container-fluid">
      <div className="row">
        <div className="col-md-6 offset-md-3 col-12">
          <h2 className="why__us__heading">The Highest Quality!</h2>
          <p>
            We use only the best quality materials and make use of a high-level
            planning machine; this gives the best quality smooth curves and the
            most exquisite quality finish.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 why__us-box">
          <div className="why__us-box">
            <img src={bag} alt="bag" />

            <p>We Imagine a Better World</p>
          </div>
        </div>
        <div className="col-md-3 why__us-box">
          <div className="why__us-box">
            <img src={world} alt="bag" />

            <p>We Are Driven to Be Great</p>
          </div>
        </div>
        <div className="col-md-3 why__us-box">
          <div className="why__us-box">
            <img src={cash} alt="cash" />

            <p>Build a Rewarding Career</p>
          </div>
        </div>
        <div className="col-md-3 why__us-box">
          <div className="why__us-box">
            <img src={present} alt="present" />

            <p>Best Quality Smooth Curves</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
