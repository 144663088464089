import React from "react";
import logo from "../../logo3.png";
import "./footer.css";

function Footer() {
  return (
    <div className="footer">
      <footer className="dark-footer skin-dark-footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-widget">
                <img src={logo} className="f-logo" alt="footer__logo" />
                <p className="mb-3">
                  Would you like to speak to one of our Project Managers over
                  the phone? Just submit your details and we'll be in touch
                  shortly. You can also email us if you would prefer
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-widget">
                <p>
                  <i className="fas fa-map-marker-alt"></i>
                  <span className="fw-600 heading">Office:</span>
                  <br /> Souvenir Engineering System
                  <br />
                  4/90, Ramesh Nagar, New Delhi-110015
                  <br />
                  <br></br>
                  <i className="fa fa-phone"></i>&nbsp;
                  <span> 9811676770, 8700040410</span>
                </p>
                <hr />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact mt-4">
                <span>
                  <i className="fa fa-envelope"></i>
                </span>
                <div className="information">
                  <strong>Email Address:</strong>
                  <p>
                    info.ses7@gmail.com, &nbsp;souvenirengineering@gmail.com
                  </p>
                </div>
              </div>
              <div className="social-media">
                  <ul>
                    <li><a href="https://www.facebook.com/sesconveyorsFBPAGE/" target="_blank" title="Facebook"><img src={process.env.PUBLIC_URL + 'assets/socialmedia/facebook.png'}></img></a></li>
                    <li><a href="https://www.linkedin.com/in/souvenir-engineering-system-985538243" target="_blank" title="Linkedin"><img src={process.env.PUBLIC_URL + 'assets/socialmedia/linkedin.png'}></img></a></li>  
                    <li><a href="https://twitter.com/SESconveyorsTR?t=NXNWcnHej-i1UKFegfUJ8Q&s=08" target="_blank" title="twitter"><img src={process.env.PUBLIC_URL + 'assets/socialmedia/twitter.png'}></img></a></li>
                    <li><a href="https://www.pinterest.com/SESconveyorsPT/?invite_code=30c6c1f4d2ee4a2e8ff8937d3f0bcf37&sender=1059120174776550755" target="_blank" title="Pinterest"><img src={process.env.PUBLIC_URL + 'assets/socialmedia/pinterest.png'}></img></a></li>
                    <li><a href="https://www.instagram.com/sesconveyors_insta/" target="_blank"><img src={process.env.PUBLIC_URL + 'assets/socialmedia/instagram.png'} title="Instagram"></img></a></li>  
                    <li><a href="https://www.quora.com/profile/Souvenir-Engineering-System?ch=10&oid=1889316214&share=59c40d58&srid=hMDrSE&target_type=user" target="_blank" title="Quora"><img src={process.env.PUBLIC_URL + 'assets/socialmedia/quora.png'}></img></a></li>  
                   

                    <li><a href="https://sesconveyorstumb.tumblr.com/" target="_blank" title="Tumblr"><img src={process.env.PUBLIC_URL + 'assets/socialmedia/tumblr.png'}></img></a></li>
                    <li><a href="https://www.reddit.com/user/sesconveyorsREDDIT?utm_medium=android_app&utm_source=share" target="_blank" title="Reddit"><img src={process.env.PUBLIC_URL + 'assets/socialmedia/reddit.png'}></img></a></li>  
                    <li><a href="https://www.youtube.com/watch?v=_O3tLeYPCvI&feature=youtu.be" target="_blank" title="Youtube"><img src={process.env.PUBLIC_URL + 'assets/socialmedia/youtube.png'}></img></a></li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 text-center">
                <p className="mb-0">
                  © 2022 Copyright&nbsp;&nbsp; Souvenir Engineering.All Rights
                  Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
