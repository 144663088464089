import React from "react";
import "./About.css";

function About() {
  return (
    <div className="About">
      <div className="about__overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="about__heading">
              About <span>Us</span>
            </h2>
            </div>
            <div className="col-md-6 text-left">
            <p className="about__discription text-justify">
              Souvenir Engineering System was established in 2014, which is
              operating as a manufacturer of Belt Conveyors, Roller Conveyors,
              Pipe and Joint Rack System , Fabrication and much more We build
              relationships that empower us to craft conveyor systems and
              warehouse automation, together.
            </p>
            <p className="about__discription text-justify">
              It's the teamwork approach, sharing ideas and building on them,
              that produces the most worthwhile material handling solutions.
              Designed Conveyor Systems takes our work very seriously. We take
              great pride in our conveyor systems and conveyor design; warehouse
              operations, design, and automation; as well as material handling
              systems, solutions, and equipment. Souvenir Engineering System is
              expertise in conveying and handling equipment, and we have seen
              products of many different shapes and sizes.
            </p>
            <p className="about__discription text-justify">
              We listen to customers and always find out about the product and
              processes within your factory. By understanding as much as
              possible about your requirements, we will be able to offer better
              solutions. All our customers expect high reliability and
              durability, and we expect nothing less from the systems we
              install.
            </p>
          </div>
          <div className="col-md-6 text-right">
          <iframe width="100%" height="409" src="https://www.youtube.com/embed/_O3tLeYPCvI" title="Aluminium belt conveyor Aluminium Conveyor Pipe & joint Conveyor system FIFO gravity storage racks" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
