import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import banner1 from "../.././banner1.jpg";
import banner2 from "../.././banner2.jpg";
import "./Banner.css";

function Banner() {
  return (
    <div className="Banner">
      <Carousel variant="dark">
        <Carousel.Item>
          <img className="d-block w-100" src={banner1} alt="banner1" />
          <Carousel.Caption>
            <h3>Manufacturer / Exporters</h3>

            <Link to="/RequestQuote" className="banner__button">
              Get a Quote
            </Link>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={banner2} alt="banner2" />
          <Carousel.Caption>
            <h3>Manufacturer / Exporters</h3>

            <Link to="/RequestQuote" className="banner__button">
              Get a Quote
            </Link>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Banner;
