import React from "react";
import emailjs from "@emailjs/browser";

class ConCat extends React.Component {
  state = {
    Name: "",
    email: "",
    message: "",
    phone: "",
    sent: false,
  };

  NameHandler = (event) => {
    this.setState({
      Name: event.target.value,
    });
  };

  emailHandler = (event) => {
    this.setState({
      email: event.target.value,
    });
  };
  phoneHandler = (event) => {
    this.setState({
      phone: event.target.value,
    });
  };
  messageHandler = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  submitRequest = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_mxemmpz",
        "template_8fw8313",
        event.target,
        "nzXAny9dvaYVMjTQA"
      )
      .then(
        (result) => {
          this.setState({ sent: true });
          event.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  render() {
    return (
      <div className="RequestQuote p-2">
        <div className="requestquote__container container p-1">
          <div className="row">
            <div className="col-12">
              <div className="request__form container-fluid">
                {this.state.sent ? (
                  <div className="alert alert-success">
                    <button
                      type="button"
                      onClick={() => {
                        this.state({ sent: false });
                      }}
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4>Thank you for getting in touch! </h4>

                    <p>
                      We appreciate you contacting Souvenir Engineering System.
                      One of our colleagues will get back in touch with you
                      soon!Have a great day!
                    </p>
                  </div>
                ) : null}
                <form onSubmit={this.submitRequest} className="ConCat">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="required-field ft-light-300">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Name"
                          name="Name"
                          value={this.Name}
                          onChange={this.NameHandler}
                          placeholder="Name"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="required-field ft-light-300">
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          value={this.email}
                          name="email"
                          onChange={this.emailHandler}
                          placeholder="type your email..."
                          required
                        />
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="required-field ft-light-300">
                          Phone Number
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={this.phone}
                          id="phone"
                          onChange={this.phoneHandler}
                          name="phone"
                          placeholder="type your mobile number..."
                          required
                        />
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="required-field ft-light-300">
                          How can we help?
                        </label>
                        <textarea
                          className="form-control"
                          id="message"
                          value={this.message}
                          onChange={this.messageHandler}
                          name="message"
                          rows="4"
                          placeholder="Hi there, I would like to....."
                          required
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <button
                        type="submit"
                        name="submit"
                        className="request__quote submit btn-block text-dark"
                      >
                        <i className="fa fa-paper-plane"></i>&nbsp;
                        <span>submit</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConCat;
