import React from "react";
import "./GalleryNavigation.css";
import { HashLink } from "react-router-hash-link";
import { Accordion } from "react-bootstrap";

const GalleryNavigation = (props) => {
  var renderCategory = () => {
    var categoryList = props.productsData.map((category) => {
      return (
        <div>
          <Accordion.Item
            eventKey={category.id}
            onClick={() => {
              props.onCategoryData(category);
            }}
            key={category.id}
          >
            <Accordion.Header> {category.name}</Accordion.Header>
            <Accordion.Body>
              <ul>
                {category.subcategories?.map((subcategory) => {
                  return (
                    <li className="subcat__name" key={subcategory.id}>
                      <HashLink to={"/Gallery#" + subcategory.name}>
                        {subcategory.name}
                      </HashLink>
                    </li>
                  );
                })}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </div>
      );
    });
    return categoryList;
  };

  return (
    <div>
      <Accordion
        defaultActiveKey={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
        alwaysOpen
      >
        {renderCategory()}{" "}
      </Accordion>
    </div>
  );
};

export default GalleryNavigation;
