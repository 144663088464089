import React, { useState } from "react";
import "./GalleryContentArea.css";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

function GalleryContentArea(props) {
  const [show, setShow] = useState(false);
  const [modalSrc, setModalSrc] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const handleClose = () => setShow(false);

  return (
    <div className="GalleryContentArea">
      {props.category.subcategories?.map((subcategory) => {
        return (
          <div key={subcategory.id}>
            <div className="gallerybox__container" id={subcategory.name}>
              <div className="row">
                <div className="col-md-12">
                  <h2 className="subcategory__name">{subcategory.name}</h2>
                </div>
              </div>
              <div className="row">
                {subcategory.productImage?.map((productInfo) => {
                  return (
                    <div className="col-md-4" key={productInfo.id}>
                      <div
                        className="gallery__box"
                        
                      >
                        <img
                          src={productInfo.imagePath}
                          title={productInfo.name}
                          alt={productInfo.name}
                          onClick={() => {
                            setShow(true);
                            setModalSrc(productInfo.imagePath);
                            setModalTitle(productInfo.name);
                          }}
                        />
                        
                        <Link to={"/ProductDetails/" +productInfo.id}><p>{productInfo.name}</p></Link>
                        {
  (productInfo.des)?( <p style={{fontWeight:"normal",fontSize:"13px",textAlign:"left"}}>{`${productInfo.des.substring(0, 110)}...`}<Link to={"/ProductDetails/" +productInfo.id} style={{textDecoration:"underline"}}>View More</Link>
  </p>):null
}
                    

                        <div className="gallery-btns d-md-none" style={{display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%"}}>
                          <div>
                          <a href="9811676770" className="btn btn-primary btn-xs"
                           style={{backgroundColor:"#d39e00",borderColor:"#d39e00",color:"#000",
                           fontWeight:"bold"}}>Call Us</a>
                         
                          </div>
                          <div>
                          <Link to={"/RequestQuote"} className="btn btn-primary btn-xs" style={{backgroundColor:"#000",borderColor:"#d39e00",color:"#fff",fontWeight:"bold"}}>Send Enquiry</Link>
                         
                          </div>

                        </div>
                        <div className="gallery-btns d-none d-sm-none  d-md-block ">
                         
                          <div class="text-center my-3">
                          <Link to={"/RequestQuote"} className="btn btn-primary btn-xs" style={{backgroundColor:"#ffc107",borderColor:"#ffc107",color:"#000",fontWeight:"bold"}}>Send Enquiry</Link>
                          </div>

                        </div>
                        
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={modalSrc} className="modal__image" alt={modalTitle}></img>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default GalleryContentArea;
