import React, { useState, useEffect } from "react";
import {useParams } from "react-router-dom";
import { stockData } from "../../data";
import ConCat from "../ConCat";
import "./ProductDetails.css";
//import emailjs from "@emailjs/browser";
//import Catcontact from "../Catcontact";


function ProductDetails() {
  const [Data, setData] = useState([]);
  useEffect(() => {
    const data = JSON.parse(JSON.stringify(stockData));   
    setData(data);
    window.scrollTo(0, 0);
    }, []);
    
  let { id } = useParams();


  return (
    <div>
        {console.log(Data)}
       {Data.map((dt) => {
      
          return (
            <div className="">
               
              {dt.subcategories?.map((subcategory) => {
                return(
                    <div>
                       
                        {subcategory.productImage?.map((productInfo) => {
                             if (productInfo.id == id) {
                            return(
                                <div>
                                  
                            {console.log(productInfo.imagePath)}         <div className="Gallery d-flex justify-content-center" style={{backgroundColor:"#ffc107"}}>

                              
                <img src={productInfo.imagePath} height="300px" className="mt-5"/>
                
              </div>
              <div className="container-fluid">
              <div className="row my-4">
              <div className="col-md-9">
              <h2 className="subcategory__name">{productInfo.name}</h2>
                                    <p>{productInfo.des}</p>
{
  (productInfo.size)?(<p><b>Size: </b>  {productInfo.size}</p>):null
}  
{
  (productInfo.shelves)?( <p><b>Shelves: </b>{productInfo.shelves}</p>):null
}  
{
  (productInfo.Material
  )?( <p><b>Material: </b>  {productInfo.Material}</p>):null
}
{
  (productInfo.profile
  )?(<p><b>Profile: </b>{productInfo.profile}</p>):null
}
{
  (productInfo.racksize
  )?(<p><b>Rack Size: </b>{productInfo.racksize}</p>):null
}                                    

                                   
                                    
                                   
                                   
                                    
                                    
                </div>
                <div className="col-md-3">
                    <ConCat></ConCat>
                </div>
                </div>

                </div>
                                    
                                </div>
                            )}})}
                    </div>
                )
              })}
            </div>
          );
        
      })}
              
             
           
        
    </div>
  );
}

export default ProductDetails;
