import React from "react";
import emailjs from "@emailjs/browser";
import "./Contactus.css";

class Contactus extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    phone: "",
    sent: false,
  };

  firstNameHandler = (event) => {
    this.setState({
      firstName: event.target.value,
    });
  };
  
  
  lastNameHandler = (event) => {
    this.setState({
      lastName: event.target.value,
    });
  };
  emailHandler = (event) => {
    this.setState({
      email: event.target.value,
    });
  };
  phoneHandler = (event) => {
    this.setState({
      phone: event.target.value,
    });
  };
  messageHandler = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  submitRequest = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_mxemmpz",
        "template_8fw8313",
        event.target,
        "nzXAny9dvaYVMjTQA"
      )
      .then(
        (result) => {
          this.setState({ sent: true });
          event.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  render() {
    return (
      <div className="Contact__us">
        <div className="contactus__banner">
          <h2 className="mt-4">Contact Us</h2>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <div className="contact__info">
                <h2>Call Us or Fill the Form</h2>
                <ul className="contact__info__list">
                  <li>
                    <div className="icon">
                      <i className="fa fa-phone"></i>
                    </div>

                    <div className="value">9811676770, 8700040410</div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fa fa-map-marker"></i>
                    </div>

                    <div className="value">
                      Souvenir Engineering System 4/90, Ramesh Nagar, New
                      Delhi-110015
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fa fa-envelope"></i>
                    </div>

                    <div className="value">
                      info.ses7@gmail.com, souvenirengineering@gmail.com
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-8">
              <div className="contact__form">
                {this.state.sent ? (
                  <div className="alert alert-success">
                    <button
                      type="button"
                      onClick={() => {
                        this.state({ sent: false });
                      }}
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4>Thank you for getting in touch! </h4>

                    <p>
                      We appreciate you contacting Souvenir Engineering System.
                      One of our colleagues will get back in touch with you
                      soon!Have a great day!
                    </p>
                  </div>
                ) : null}
                <form enctype="multipart/form-data" method="post" onSubmit={this.submitRequest}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="required-field ft-light-300">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          name="firstName"
                          value={this.firstName}
                          onChange={this.firstNameHandler}
                          placeholder="First Name"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="required-field ft-light-300">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          name="lastName"
                          value={this.lastName}
                          onChange={this.lastNameHandler}
                          placeholder="Last Name"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="required-field ft-light-300">
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          value={this.email}
                          name="email"
                          onChange={this.emailHandler}
                          placeholder="type your email..."
                          required
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="required-field ft-light-300">
                          Phone Number
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={this.phone}
                          id="phone"
                          onChange={this.phoneHandler}
                          name="phone"
                          placeholder="type your mobile number..."
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                    <label>Attach file:</label>
    <input type="file" name="my_file"/> 
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="required-field ft-light-300">
                          How can we help?
                        </label>
                        <textarea
                          className="form-control"
                          id="message"
                          value={this.message}
                          onChange={this.messageHandler}
                          name="message"
                          rows="4"
                          placeholder="Hi there, I would like to....."
                          required
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <button
                        type="submit"
                        name="submit"
                        className="btn btn-primary btn-block submit"
                      >
                        <i className="fa fa-paper-plane"></i>
                        <span>submit</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contactus;
