import React from "react";
import { Link } from "react-router-dom";
import "./Services.css";

function Services(props) {
  var renderCategory = () => {
    var categoryList = props.productsData.map((category) => {
      return (
        <div className="col-md-3" key={category.id}>
          <Link to={"/Gallery#"+ category.subcategories[0].name}>
            <div className="service__block">
              <img
                src={category.imagePath}
                className="service_image"
                alt={category.name}
              />
            </div>

            <h2 className="service__title">{category.name}</h2>
          </Link>
        </div>
      );
    });
    return categoryList;
  };
  return (
    <div className="services_cat">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <h2 className="services__heading">
              Our <span>Services</span>
            </h2>
            <p className="description text-center">
              Souvenir Engineering System can help and discuss with you what you
              need and design a bespoke solution for your business.
            </p>
          </div>
        </div>
        <div className="row">{renderCategory()}</div>
      </div>
    </div>
  );
}

export default Services;
