import React, { useState, useEffect } from "react";
import Banner from "./Banner";
import Services from "./Services";
import About from "./About";
import WhyUs from "./WhyUs";
import { stockData } from "../../data";
function Home() {
  const [Data, setData] = useState([]);
  useEffect(() => {
    const data = JSON.parse(JSON.stringify(stockData));
    setData(data);
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Banner />
      <Services productsData={Data} />

      <About />
      <WhyUs />
    </React.Fragment>
  );
}

export default Home;
