import React from "react";
import { Link } from "react-router-dom";
import "./Aboutcomp.css";
function About() {
  return (
    <div className="About__comp">
      <div className="about__banner">
        <h2 className="mt-4">About us</h2>
      </div>
      <div className="about__details container-fluid">
        <h3>About Souvenir Engineering System </h3>
        <p>
          Souvenir Engineering System was established in 2014, which is
          operating as a manufacturer of Belt Conveyors, Roller Conveyors, Pipe
          and Joint Rack System , Fabrication and much more
        </p>

        <p>
          We build relationships that empower us to craft conveyor systems and
          warehouse automation, together. It's the teamwork approach, sharing
          ideas and building on them, that produces the most worthwhile material
          handling solutions. Designed Conveyor Systems takes our work very
          seriously. We take great pride in our conveyor systems and conveyor
          design; warehouse operations, design, and automation; as well as
          material handling systems, solutions, and equipment.
        </p>

        <h4>Why Choose Us</h4>
        <p>
          Souvenir Engineering System is expertise in conveying and handling
          equipment, and we have seen products of many different shapes and
          sizes. We listen to customers and always find out about the product
          and processes within your factory. By understanding as much as
          possible about your requirements, we will be able to offer better
          solutions. All our customers expect high reliability and durability,
          and we expect nothing less from the systems we install.
        </p>

        <h4>Design</h4>
        <p>
          Designing a conveyor system involves careful consideration of
          operators, machines throughput, capability and down time. Even
          accounting for tea breaks and shift changes can help us design a line
          which will not have bottlenecks, and have the right product buffers to
          keep production moving smoothly and efficiently.
        </p>

        <h4>Suppliers</h4>
        <p>
          We only use suppliers which we can work with, and will work well with
          us. We know that support from our suppliers and their ability to work
          with us is very important to provide a successful complete solution.
        </p>
        <h4>Customers</h4>
        <p>
          We are fortunate to have some great customers who come back to us
          again and again. We never take this for granted, and we work hard to
          keep all our customers happy. We know that keeping customers is
          extremely important, so our goal is to deliver systems and service
          which will keep you happy and make you want to use us again in the
          future.
        </p>
        <h4>Staff</h4>
        <p>
          Our staff are the key to our business and we have a great team. We
          cannot operate effectively without people who work well together and
          have the right experience. Everybody is amazingly committed to getting
          the job done right and working to their best abilities. We are very
          proud of our staff, their positive approach and their effort they put
          in to keeping customers happy.
        </p>

        <h4>Flexibility</h4>
        <p>
          We have a flexible attitude. If last minute changes need to be
          considered, if a customer suddenly has to delay an installation or if
          an installation needs to move to out of hours to avoid disruption. We
          will work with you and try to accommodate this as much as possible.
        </p>

        <h4>Enjoyment</h4>
        <p>
          We enjoy our work, and this is very important. We enjoy the challenges
          which come up with each individual conveyor or full project. We hope
          this reflect in the service and the contact you have with us. A busy
          and enjoyable working atmosphere is important to us all.
        </p>
        <p>
          <Link to={"/Gallery"} className="btn btn-secondary about__btn">
            Click here to see Our products
          </Link>
        </p>
      </div>
    </div>
  );
}

export default About;
